import { render, staticRenderFns } from "./title1.vue?vue&type=template&id=17fbc980&scoped=true&"
import script from "./title1.ts?vue&type=script&lang=ts&"
export * from "./title1.ts?vue&type=script&lang=ts&"
import style0 from "./title1.vue?vue&type=style&index=0&id=17fbc980&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17fbc980",
  null
  
)

export default component.exports